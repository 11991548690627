export const ADSERVER_CONFIG_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/adServer";
export const AD_CONFIG_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/AdConfig";
export const WYNK_CLIENT_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/wynkclient";
export const AD_CLIENT_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/adClient";
export const SLOT_PRIORITY_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/slotSourcePriority";
export const AD_PROPERTIES_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/adProperties";
export const AD_RECOMMENDATIONS_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/adRecommendation";
export const BLACKLISTED_OFFER_ID_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/adsOfferBlacklisted";

export const FEATURE_CONTROL_RULE_ENDPOINT =
  process.env.REACT_APP_RULE_ENGINE_URL + "feature-control-rule";
export const FEATURE_CONTROL_ENDPOINT =
  process.env.REACT_APP_RULE_ENGINE_URL + "feature-control";

export const SAVE_USER_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/user/save";
export const UPDATE_USER_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/user/update";
export const SAVE_CREDENTIALS_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/credentials/save";
export const UPDATE_CREDENTIALS_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/credentials/update";
export const FORGOT_PASSWORD_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL +
  "api/v1/credentials/forgotPassword";
export const GET_ALL_USER_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/user/getAll";
export const USER_APPROVAL_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/userApproval";

export const USER_LOGIN_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/user/login";

export const USER_LOGOUT_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/user/logout";
export const AUTHENTICATE_TOKEN_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHENTICATION_URL + "api/v1/authenticate";

export const USER_ACCESS_ROLE_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHORIZATION_URL + "api/v1/userRole";
export const TENANT_ROLE_ENDPOINT =
  process.env.REACT_APP_ADTECH_AUTHORIZATION_URL + "api/v1/tenant";

export const PERSONALIZATION_SCHEDULER_ENDPOINT =
  process.env.REACT_APP_ADTECH_PERSONALIZATION_SCHEDULER_URL +
  "file/processing";

export const NEW_AD_CONFIG_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "wynk/api/newSlotConfigs";
// export const NEW_AD_CONFIG_ENDPOINT = 'http://localhost:8081/wynk/api/newSlotConfigs';

export const IMP_CONFIG_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "imp/config";

export const ACCOUNT_ENDPOINT = process.env.REACT_APP_ADCONFIG_URL + "account";

export const AD_UNIT_CONFIG_ENDPOINT =
  process.env.REACT_APP_ADCONFIG_URL + "adUnit/config";
