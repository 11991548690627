export const AdTypes = {
  "AUDIO_AD" : ["PRE_ROLL", "MID_ROLL"], 
  "VIDEO_AD" : ["PRE_ROLL", "MID_ROLL"], 
  "BANNER_AD" : ["MAST_HEAD"], 
  "INTERSTITIAL_AD" : ["MAST_HEAD"]
}



export const PublisherType = {
  "APP" : "app", 
  "SITE" : "site", 
}

export const AuctionTypeOptions = {
  "First Price" : 1,
  "Second Price" : 2
}

export const PrebidAdTypes = [
  "banner", "video"
]

export const AdStates = [
  "ACTIVE_STATE", "INACTIVE_STATE"
]

export const Services = [
  "music" , "airteltv"
]

export const PriceGranularity = [
  "low" , "medium" , "high"
]

export const VerbosityLevel = [
  "basic" , "none" , "detailed"
]

export const BooleanFlag = [
  "TRUE" , "FALSE"
]

export const BidValidationEnforcement = [
  "skip" , "enforce" , "warn"
]

export const IFATPlatformMap = {
  "ANDROID" : "GAID",
  "IOS" : "IDFA"
}

export const CreativeFormatList = [
  { value: 'NATIVE', label: 'NATIVE' },
  { value: 'CUSTOM', label: 'CUSTOM' },
  { value: 'BANNER', label: 'BANNER' },
  { value: 'REWARDED', label: 'REWARDED' },
  {value: 'REWARDED_INTERSTITIAL', label: 'REWARDED_INTERSTITIAL'},
]


export const PlatformOptions = [
  {label:"Android", value:"Android"},
  {label:"IOS", value:"IOS"}
]

export const PlatformList = [
   "ANDROID", "IOS", "WEBOS", "MWEBOS","TIZENOS","WINDOWS","MACOS","CHROMEOS","TVOS"
]

export const FileStatusList = ["TO_BE_PROCESSED", "COMPLETED", "ERROR", "IN_PROGRESS"]

export const SLOT_LEVEL_RETRY = [
  "ENABLE", "DISABLE"
]

export const LOADING_STRATEGY =[
  "HEADER_BIDDING"
]

export const AD_TYPE =[
  "BANNER", "VIDEO"
]

export const IMP_STATE= ["ACTIVE","INACTIVE"]
  